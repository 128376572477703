<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M5 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V18C21 18.5304 20.7893 19.0391 20.4142 19.4142C20.0391 19.7893 19.5304 20 19 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4ZM5 8V12H11V8H5ZM13 8V12H19V8H13ZM5 14V18H11V14H5ZM13 14V18H19V14H13Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
