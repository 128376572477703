<script
    lang="ts"
    setup
>
    import { KBItemTypeEnum } from '~/ts/enums/knowledge-base'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()
</script>

<template>
    <div
        :class="[
            `
                pointer-events-none
                whitespace-nowrap
                truncate
                flex
                items-center
                gap-1.5
                py-2
                px-3
                text-[14px]
                text-[#8a8f9e]
                bg-[#f6f7f8]
                rounded-[8px]
            `,
            { 'ml-4': knowledgeBaseStore.currentArticle.parentItem.type !== KBItemTypeEnum.Tree },
        ]"
    >
        <AppIconDocumentOutlineEdit size="16" />

        {{ knowledgeBaseStore.currentArticle.title || $t('new-article') }}
    </div>
</template>
