<script
    lang="ts"
    setup
>
    import type { Editor } from '@tiptap/vue-3'

    type Props = {
        editor: Editor
    }

    const props = defineProps<Props>()
</script>

<template>
    <AppDropdown
        width="220"
        content-class="flex flex-col gap-1"
    >
        <template #activator="{ toggle, active }">
            <button
                type="button"
                :class="[
                    'app-text-editor-menu__button',
                    { 'app-text-editor-menu__button--active': active },
                    'gap-2',
                ]"
                style="width: 100%; border-right: 1px solid #e3e5eb"
                @click="toggle"
            >
                <AppIconTableRow
                    size="20"
                />

                {{ $t('row') }}

                <AppIconChevronDown
                    size="20"
                    :rotate="active"
                />
            </button>
        </template>

        <template #default="{ close }">
            <AppDropdownItem
                v-if="props.editor.can().addRowAfter()"
                key="add-row"
                style="font-weight: 500"
                @click="props.editor.chain().focus().addRowAfter().run(); close()"
            >
                <AppIconPlus
                    size="20"
                    style="margin-right: 16px"
                />

                {{ $t('add-row') }}
            </AppDropdownItem>

            <AppDropdownItem
                v-if="props.editor.can().deleteRow()"
                key="delete-row"
                style="font-weight: 500"
                @click="props.editor.chain().focus().deleteRow().run(); close()"
            >
                <AppIconTrash
                    size="20"
                    style="margin-right: 16px"
                />

                {{ $t('delete-row') }}
            </AppDropdownItem>

            <AppDropdownItem
                v-if="props.editor.can().toggleHeaderRow()"
                key="toggle-row-header"
                style="font-weight: 500"
                @click="props.editor.chain().focus().toggleHeaderRow().run(); close()"
            >
                <AppIconTableRow
                    size="20"
                    style="margin-right: 16px"
                />

                {{ $t('row-header') }}
            </AppDropdownItem>
        </template>
    </AppDropdown>

    <AppDropdown
        from-right
        offset-x="0"
        offset-y="10"
        width="220"
        content-class="flex flex-col gap-1"
    >
        <template #activator="{ toggle, active }">
            <button
                type="button"
                :class="[
                    'app-text-editor-menu__button',
                    { 'app-text-editor-menu__button--active': active },
                    'gap-2',
                ]"
                style="width: 100%"
                @click="toggle"
            >
                <AppIconTableColumn
                    size="20"
                />

                {{ $t('column') }}

                <AppIconChevronDown
                    size="20"
                    :rotate="active"
                />
            </button>
        </template>

        <template #default="{ close }">
            <AppDropdownItem
                v-if="props.editor.can().addColumnAfter()"
                key="add-column"
                style="font-weight: 500"
                @click="props.editor.chain().focus().addColumnAfter().run(); close()"
            >
                <AppIconPlus
                    size="20"
                    style="margin-right: 16px"
                />

                {{ $t('add-column') }}
            </AppDropdownItem>

            <AppDropdownItem
                v-if="props.editor.can().deleteColumn()"
                key="delete-column"
                style="font-weight: 500"
                @click="props.editor.chain().focus().deleteColumn().run(); close()"
            >
                <AppIconTrash
                    size="20"
                    style="margin-right: 16px"
                />

                {{ $t('delete-column') }}
            </AppDropdownItem>

            <AppDropdownItem
                v-if="props.editor.can().toggleHeaderColumn()"
                key="toggle-column-header"
                style="font-weight: 500"
                @click="props.editor.chain().focus().toggleHeaderColumn().run(); close()"
            >
                <AppIconTableColumn
                    size="20"
                    style="margin-right: 16px"
                />

                {{ $t('column-header') }}
            </AppDropdownItem>
        </template>
    </AppDropdown>
</template>
