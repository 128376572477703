<script
    lang="ts"
    setup
>
    import type { Editor } from '@tiptap/vue-3'
    import { BubbleMenu, FloatingMenu } from '@tiptap/vue-3'

    type Props = {
        editor: Editor
        show: boolean
    }

    const props = defineProps<Props>()

    const shouldShowFloatingMenu = ({ state, editor }) => {
        if (!editor.isFocused) {
            return false
        }

        const { selection } = state
        const { $anchor, empty } = selection
        const isRootDepth = $anchor.depth === 1
        const isEmptyTextBlock = $anchor.parent.isTextblock
            && !$anchor.parent.type.spec.code
            && !$anchor.parent.textContent

        if (!empty || !isRootDepth || !isEmptyTextBlock) {
            return isEmptyTextBlock || (empty && props.editor.isActive('table'))
        }

        return true
    }
</script>

<template>
    <FloatingMenu
        v-show="props.show"
        :should-show="shouldShowFloatingMenu"
        :editor="props.editor"
        :tippy-options="{ duration: 100, placement: 'top-start' }"
        class="app-text-editor-menu"
    >
        <AppTextEditorMenusTableOptions
            v-if="props.editor.isActive('table')"
            key="table-options"
            :editor="props.editor"
        />

        <template v-else>
            <AppTextEditorMenusTextOptions :editor="props.editor" />

            <AppTextEditorMenusImage :editor="props.editor" />

            <AppTextEditorMenusListOptions :editor="props.editor" />

            <button
                type="button"
                class="app-text-editor-menu__button"
                style="border-right: 1px solid #e3e5eb"
                @click="props.editor.chain().focus().insertTable({ rows: 2, cols: 2, withHeaderRow: false }).run()"
            >
                <AppIconTable size="16" />
            </button>

            <AppTextEditorMenusTextStyleOptions :editor="props.editor" />
        </template>
    </FloatingMenu>

    <BubbleMenu
        :editor="props.editor"
        :tippy-options="{ duration: 100 }"
        class="app-text-editor-menu"
    >
        <AppTextEditorMenusTextOptions :editor="props.editor" />

        <AppTextEditorMenusListOptions :editor="props.editor" />

        <AppTextEditorMenusLink :editor="props.editor" />

        <AppTextEditorMenusTextStyleOptions :editor="props.editor" />
    </BubbleMenu>
</template>

<style lang="sass">
    .app-text-editor-menu
        display: flex
        background: #fff
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1)
        border-radius: 8px

        &__button
            appearance: none
            white-space: nowrap
            display: flex
            align-items: center
            padding: 8px
            vertical-align: middle
            font: inherit
            font-style: normal
            font-weight: 500
            text-align: center
            outline: none
            border: none
            background: transparent
            color: #aaadb8
            cursor: pointer
            user-select: none

            &:hover,
            &--active
                color: #000

                > svg > path
                    fill: #000
</style>
