<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { KBArticle, KBItem } from '~/ts/types/knowledge-base'
    import { KBItemStatusEnum, KBItemTypeEnum } from '~/ts/enums/knowledge-base'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    type Props = {
        item: KBItem
        dataKey: string
        dataParentKey: string
        dragging: boolean
    }

    type Emit = {
        (event: 'start-drag', dragEvent: DragEvent): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const contextMenuRef = ref<ReturnType<typeof defineComponent>>()

    const isItemAnArticle = computed<boolean>(() => props.item.type === KBItemTypeEnum.Article)
    const isArticleSelected = computed<boolean>(() => {
        return isItemAnArticle.value && (knowledgeBaseStore.currentArticle?._id === props.item._id)
    })
    const isArticlePublished = computed<boolean>(() => {
        if (!isItemAnArticle.value) {
            return false
        }

        return (props.item as KBArticle).status === KBItemStatusEnum.Published
    })
    const isCategoryOpenValue = ref<boolean>(false)
    const isCategoryOpen = computed<boolean>(() => {
        if (knowledgeBaseStore.search) {
            return true
        }

        return isCategoryOpenValue.value
    })
    const dropdownShowCategoryForm = computed<boolean>(() => {
        return [ 'create-category', 'edit-category' ].includes(contextMenuRef.value?.activeDetails)
    })

    const dataAttrs = computed(() => ({
        'data-key': props.dataKey,
        'data-parent-key': props.dataParentKey,
        'data-has-children': props.item.type === KBItemTypeEnum.Category,
    }))

    const onClick = (): void => {
        if (props.item.type === KBItemTypeEnum.Article) {
            knowledgeBaseStore.selectArticle(props.item as KBArticle)
        } else if (props.item.type === KBItemTypeEnum.Category) {
            isCategoryOpenValue.value = !isCategoryOpenValue.value
        }
    }

    const deleteCategory = async (close: AnyFn) => {
        const { t } = useLang()

        if (!await useConfirm({
            titleText: t('category-deleting'),
            bodyText: t('category-deleting-hint'),
            confirmText: t('remove'),
        })) {
            return
        }

        try {
            await useApi().knowledgeBase.deleteCategory({ id: props.item._id })

            close()

            const parentItem = knowledgeBaseStore.findItemParent(props.item)

            parentItem.children = parentItem.children.filter(v => v._id !== props.item._id)

            if (knowledgeBaseStore.currentArticle.new) {
                if (knowledgeBaseStore.currentArticle.parentItem._id === props.item._id) {
                    await knowledgeBaseStore.setFirstOrNewArticle()
                }
            } else if (!knowledgeBaseStore.allArticles.find(v => v._id === knowledgeBaseStore.currentArticle._id)) {
                await knowledgeBaseStore.setFirstOrNewArticle()
            }

            useNotify().push({
                type: 'success',
                tag: 'kb',
                text: useLang().t('successfully-deleted'),
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    }
</script>

<template>
    <AppDropdown
        :key="props.item._id"
        ref="contextMenuRef"
        :from-right="!dropdownShowCategoryForm"
        width="auto"
    >
        <template #activator="{ toggle, active, activeDetails }">
            <div
                v-bind="dataAttrs"
                draggable="true"
                data-draggable="true"
                class="kb-nav-item group/item relative"
                @dragstart="emit('start-drag', $event)"
            >
                <div
                    key="content"
                    :class="[
                        `
                            kb-nav-item__content
                            relative
                            flex
                            items-center
                            gap-1.5
                            py-2
                            px-3
                            text-[14px]
                            bg-white
                            rounded-[8px]
                            cursor-pointer
                            hover:bg-[#f6f7f8]
                        `,
                        { '!bg-[#f6f7f8]': isArticleSelected },
                        { 'text-[#8a8f9e]': isItemAnArticle && !isArticlePublished },
                        { 'pointer-events-none': props.dragging },
                    ]"
                    @click="onClick()"
                >
                    <AppIconMenuArrowDown
                        v-if="props.item.type === KBItemTypeEnum.Category"
                        key="icon-arrow"
                        size="16"
                        :class="[
                            'pointer-events-none -rotate-90',
                            { 'rotate-0 [&_path]:fill-black': isCategoryOpen },
                        ]"
                    />

                    <div
                        key="title"
                        class="pointer-events-none overflow-hidden flex items-center gap-1.5"
                    >
                        <AppIconDocument
                            v-if="props.item.type === KBItemTypeEnum.Article"
                            size="16"
                            :color="(isItemAnArticle && !isArticlePublished) ? '#8a8f9e' : '#000'"
                        />

                        <div class="overflow-hidden">
                            <div class="whitespace-nowrap truncate">
                                {{ props.item.title }}
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="props.item.type === KBItemTypeEnum.Category"
                        key="actions"
                        :class="[
                            'absolute opacity-0 right-0 flex items-center px-1 bg-inherit group-hover/item:opacity-100',
                            { 'opacity-100': active },
                        ]"
                    >
                        <AppButtonIcon
                            v-slot="{ color }"
                            extra-small
                            :active="activeDetails === 'more'"
                            @click.stop="toggle('more')"
                        >
                            <AppIconMore
                                size="16"
                                :color="color"
                            />
                        </AppButtonIcon>

                        <AppButtonIcon
                            v-slot="{ color }"
                            extra-small
                            :active="activeDetails === 'add'"
                            @click.stop="toggle('add')"
                        >
                            <AppIconPlus
                                size="16"
                                :color="color"
                            />
                        </AppButtonIcon>
                    </div>
                </div>

                <template v-if="(props.item.type === KBItemTypeEnum.Category) && isCategoryOpen">
                    <template
                        v-for="(nestedItem, nestedIndex) in props.item.children"
                        :key="nestedItem._id"
                    >
                        <KnowledgeBaseNavigationArticleNew
                            v-if="nestedItem.new"
                            key="new-article"
                        />

                        <KnowledgeBaseNavigationItem
                            v-else
                            :key="nestedItem._id"
                            :item="nestedItem"
                            :data-key="props.dataKey + '-' + nestedIndex"
                            :data-parent-key="props.dataKey"
                            :dragging="props.dragging"
                            class="ml-4"
                            @start-drag="emit('start-drag', $event)"
                        />
                    </template>
                </template>
            </div>
        </template>

        <template #default="{ activeDetails, updateActiveDetails, close }">
            <KnowledgeBaseNavigationCategoryForm
                v-if="dropdownShowCategoryForm"
                key="category-form"
                :item-or-parent-item="props.item"
                :editing="activeDetails === 'edit-category'"
                @close="close"
            />

            <div
                v-else-if="activeDetails === 'add'"
                key="adding-menu"
                class="flex flex-col gap-1 w-[224px]"
            >
                <AppDropdownItem @click="updateActiveDetails('create-category')">
                    <AppIconFolderAdd size="20" />

                    <span class="ml-4 font-medium">
                        {{ $t('create-category') }}
                    </span>
                </AppDropdownItem>

                <AppDropdownItem @click="knowledgeBaseStore.createArticle(props.item, close)">
                    <AppIconDocumentAdd size="20" />

                    <span class="ml-4 font-medium">
                        {{ $t('create-article') }}
                    </span>
                </AppDropdownItem>
            </div>

            <div
                v-else
                key="edit-menu"
                class="flex flex-col gap-1 w-[264px]"
            >
                <AppDropdownItem @click="updateActiveDetails('edit-category')">
                    <AppIconPencil size="20" />

                    <span class="ml-4 font-medium">
                        {{ $t('rename-category') }}
                    </span>
                </AppDropdownItem>

                <AppDropdownItem @click="deleteCategory(close)">
                    <AppIconTrash size="20" />

                    <span class="ml-4 font-medium">
                        {{ $t('delete-category') }}
                    </span>
                </AppDropdownItem>
            </div>
        </template>
    </AppDropdown>
</template>

<style
    lang="sass"
    scoped
>
    .kb-nav-item
        &[data-drag-action='insertBefore'] > &__content:before
            @apply content-[''] absolute top-0 left-0 w-full h-[2px] bg-gray-900 rounded-[8px]

        &[data-drag-action='appendTo'] > &__content
            @apply bg-[#cfd2d6]

        &[data-drag-action='insertAfter'] > &__content:after
            @apply content-[''] absolute left-0 bottom-0 w-full h-[2px] bg-gray-900 rounded-[8px]
</style>
