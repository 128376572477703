<script
    lang="ts"
    setup
>
    import type { Editor } from '@tiptap/vue-3'

    type Props = {
        editor: Editor
    }

    const props = defineProps<Props>()
</script>

<template>
    <AppDropdown
        width="auto"
        content-class="flex flex-col gap-1"
    >
        <template #activator="{ toggle, active }">
            <button
                type="button"
                :class="[
                    'app-text-editor-menu__button',
                    { 'app-text-editor-menu__button--active': active },
                ]"
                style="border-right: 1px solid #e3e5eb"
                @click="toggle"
            >
                <AppIconListBulleted size="16" />

                <AppIconChevronDown
                    size="16"
                    :rotate="active"
                    style="margin-left: 2px"
                />
            </button>
        </template>

        <template #default="{ close }">
            <AppDropdownItem
                :active="editor.isActive('bulletList')"
                @click="props.editor.chain().focus().toggleBulletList().run(); close()"
            >
                <AppIconListBulleted size="20" />
            </AppDropdownItem>

            <AppDropdownItem
                :active="editor.isActive('orderedList')"
                @click="props.editor.chain().focus().toggleOrderedList().run(); close()"
            >
                <AppIconListNumbered size="20" />
            </AppDropdownItem>
        </template>
    </AppDropdown>
</template>
