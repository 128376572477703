<template>
    <div :class="$style['page']">
        <slot />
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .page
        display: flex
        flex-direction: column
        width: 100%
        height: 100%
</style>
