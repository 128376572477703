<script
    lang="ts"
    setup
>
    import type { AnyFn } from '~/ts/types/common'
    import type { KBCategory, KBTreeItem } from '~/ts/types/knowledge-base'
    import type { FormSubmitContext } from '~/ts/types/form'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'
    import handleFormError from '~/helpers/handleFormError'
    import rules from '~/helpers/formValidationRules'

    type Props = {
        itemOrParentItem: KBCategory | KBTreeItem
        editing?: boolean
    }

    type Emit = {
        (event: 'close'): void
    }

    type FormRules = {
        title: AnyFn
    }

    type FormValues = {
        title: string
    }

    const props = withDefaults(defineProps<Props>(), {
        editing: false,
    })

    const emit = defineEmits<Emit>()

    const titleRef = ref<ReturnType<typeof defineComponent>>()

    useFieldControl(
        titleRef,
        {
            nestedRefName: 'inputRef',
            autofocus: true,
            startTyping: true,
        },
    )

    const formRules: FormRules = {
        title: rules.required,
    }

    const formValues = ref<FormValues>({
        title: props.editing ? props.itemOrParentItem.title : '',
    })

    const pending = ref<boolean>(false)

    const submit = async ({ title }: FormValues, { setFieldError }: FormSubmitContext): Promise<void> => {
        if (pending.value) {
            return
        }

        pending.value = true

        const knowledgeBaseStore = useKnowledgeBaseStore()

        const { data, error } = await (
            props.editing
                ? useApi().knowledgeBase.updateCategory({
                    contentLanguage: knowledgeBaseStore.contentLanguage,
                    id: props.itemOrParentItem._id,
                    data: { title },
                })
                : useApi().knowledgeBase.createCategory({
                    contentLanguage: knowledgeBaseStore.contentLanguage,
                    data: {
                        node_id: props.itemOrParentItem._id,
                        title,
                    },
                })
        )

        if (error.value) {
            pending.value = false

            return handleFormError({ error, setFieldError })
        }

        emit('close')

        const itemOrParentItem = knowledgeBaseStore
            .findItemById(props.itemOrParentItem._id) as KBCategory | KBTreeItem

        if (props.editing) {
            Object.assign(itemOrParentItem, data.value)
        } else {
            itemOrParentItem.children.push({ ...data.value, children: [] } as any)
        }

        useNotify().push({
            type: 'success',
            tag: 'kb',
            text: useLang().t(props.editing ? 'successfully-updated' : 'successfully-created'),
        })
    }
</script>

<template>
    <AppForm
        v-slot="{ errors }"
        :rules="formRules"
        :values="formValues"
        class="!flex-row"
        @submit="submit"
    >
        <AppFormFieldInput
            ref="titleRef"
            v-model.trim="formValues.title"
            v-model:error="errors.title"
            name="title"
            :label="$t(props.editing ? 'category' : 'new-category')"
            class="!w-[328px] !mb-0"
        >
            <template #suffix>
                <AppButton
                    type="submit"
                    :loading="pending"
                    class="!py-3 !px-4"
                >
                    {{ $t(props.editing ? 'save' : 'create') }}
                </AppButton>
            </template>
        </AppFormFieldInput>
    </AppForm>
</template>
