<script
    lang="ts"
    setup
>
    import type { Editor } from '@tiptap/vue-3'

    type Props = {
        editor: Editor
    }

    const props = defineProps<Props>()

    const appConfig = useAppConfig()

    const imageInputAccept = computed<string>(() => appConfig.supportedImageTypesToUpload.join(','))

    const loadImage = async (event) => {
        const { files } = event.target

        if (!files || !files[0]) {
            return
        }

        if (!fileUtil.validateFileSize(files[0])) {
            useNotify().push({
                type: 'error',
                text: useLang().t('file-must-be-less-than-5mb'),
            })
        } else {
            props.editor
                .chain()
                .focus()
                .setImage({ src: await fileUtil.getBase64(files[0]) })
                .run()
        }

        event.target.value = ''
    }
</script>

<template>
    <button
        type="button"
        :class="[
            'app-text-editor-menu__button',
            { 'app-text-editor-menu__button--active': props.editor.isActive('image') },
        ]"
        style="border-right: 1px solid #e3e5eb"
        @click="$refs.fileRef.click()"
    >
        <AppIconImage size="16" />
    </button>

    <input
        ref="fileRef"
        type="file"
        :accept="imageInputAccept"
        style="display: none"
        @change="loadImage"
    />
</template>
