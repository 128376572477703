<script
    lang="ts"
    setup
>
    type Props = {
        showSidebar: boolean
    }

    type Emit = {
        (event: 'update:showSidebar', value: boolean): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const showSidebar = useSyncProp(props, 'showSidebar', emit)

    const bus = useEventBus()

    const saveArticle = (): void => {
        bus.emit(BUS_EVENT_KB_SAVE_ARTICLE)
    }
</script>

<template>
    <div
        class="
            max-tablet:z-[10]
            max-tablet:fixed
            tablet:absolute
            flex
            items-center
            justify-between
            gap-1
            w-[164px]
            left-[calc(50%-82px)]
            bottom-28
            tablet:bottom-16
            p-1
            bg-white
            shadow-2xl
            rounded-[12px]
        "
    >
        <AppButton @click="saveArticle()">
            {{ $t('save') }}
        </AppButton>

        <AppButtonIcon
            small
            class="mx-1"
            @click="showSidebar = true"
        >
            <AppIconCog />
        </AppButtonIcon>
    </div>
</template>
