<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.06995 2.90002V12.56C5.06995 14.5227 5.69861 16.125 6.95595 17.367C8.21328 18.609 9.84628 19.23 11.8549 19.23C13.8636 19.23 15.4966 18.609 16.7539 17.367C18.0113 16.125 18.6399 14.5227 18.6399 12.56V2.90002H16.1099V12.56C16.1099 13.756 15.6883 14.7834 14.8449 15.642C14.0016 16.5007 13.0049 16.93 11.8549 16.93C10.6436 16.93 9.63161 16.516 8.81895 15.688C8.00628 14.86 7.59995 13.8174 7.59995 12.56V2.90002H5.06995ZM19 21H5V23H19V21Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
