<script
    lang="ts"
    setup
>
    import type { Editor } from '@tiptap/vue-3'
    import type { AnyFn } from '~/ts/types/common'
    import rules from '~/helpers/formValidationRules'

    type Props = {
        editor: Editor
    }

    type FormRules = {
        url: AnyFn
    }

    type FormValues = {
        url: string
    }

    const props = defineProps<Props>()

    const dropdownRef = ref<ReturnType<typeof defineComponent>>()
    const urlRef = ref<ReturnType<typeof defineComponent>>()

    const formRules: FormRules = {
        url: rules.url,
    }

    const formValues = ref<FormValues>({
        url: '',
    })

    const editing = ref<boolean>(false)

    const submit = ({ url }: FormValues): void => {
        if (url === '') {
            props.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .unsetLink()
                .run()
        } else {
            props.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url, target: '_blank' })
                .run()
        }

        dropdownRef.value.close()
    }

    const onOpen = () => {
        formValues.value.url = props.editor.getAttributes('link').href

        editing.value = !!formValues.value.url

        setTimeout(() => {
            urlRef.value.$refs.inputRef.focus()
        }, 0)
    }
</script>

<template>
    <AppDropdown
        ref="dropdownRef"
        width="auto"
        content-class="flex flex-col gap-1"
        @opened="onOpen()"
    >
        <template #activator="{ toggle, active }">
            <button
                type="button"
                :class="[
                    'app-text-editor-menu__button',
                    { 'app-text-editor-menu__button--active': active || props.editor.isActive('link') },
                ]"
                style="border-right: 1px solid #e3e5eb"
                @click="toggle"
            >
                <AppIconLink size="16" />

                <AppIconChevronDown
                    size="16"
                    :rotate="active"
                    style="margin-left: 2px"
                />
            </button>
        </template>

        <template #default="{ close }">
            <AppForm
                v-slot="{ errors, triggerSubmit }"
                :rules="formRules"
                :values="formValues"
                @submit="submit"
            >
                <AppFormFieldTextarea
                    ref="urlRef"
                    v-model.trim="formValues.url"
                    v-model:error="errors.url"
                    name="url"
                    :label="$t('address')"
                    small
                    class="!mb-2"
                    @keydown.enter.prevent="triggerSubmit()"
                />

                <AppFormActions class="!mt-0">
                    <AppButton
                        secondary
                        @click="close()"
                    >
                        {{ $t('cancel') }}
                    </AppButton>

                    <AppButton type="submit">
                        {{ editing ? $t('save') : $t('create') }}
                    </AppButton>
                </AppFormActions>
            </AppForm>
        </template>
    </AppDropdown>
</template>
