<script
    lang="ts"
    setup
>
    import type { FormSelectOption } from '~/ts/types/form'
    import type { Language } from '~/ts/types/language'
    import { useUserStore } from '~/stores/user'
    import { useLanguageStore } from '~/stores/language'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const route = useRoute()

    const { t } = useLang()
    const { maxTablet } = useWindowSize()

    const userStore = useUserStore()
    const languageStore = useLanguageStore()
    const knowledgeBaseStore = useKnowledgeBaseStore()

    const pending = ref<boolean>(false)

    const options = computed<FormSelectOption[]>(() => {
        const options = [
            {
                ...languageStore.languageOptionsByValue[userStore.currentProject.language],
                group: t('main-language'),
            },
        ]

        for (const option of languageStore.languageOptions) {
            if (option.value === userStore.currentProject.language) {
                continue
            }

            options.push({
                ...option,
                group: t('other-languages'),
            })
        }

        return options
    })

    const iconComponents = {
        uk: resolveComponent('AppIconLanguageUK'),
        en: resolveComponent('AppIconLanguageEN'),
        ru: resolveComponent('AppIconLanguageRU'),
    }

    const changeContentLanguage = async (lang: Language): Promise<void> => {
        if (pending.value || !await knowledgeBaseStore.handleArticleChangesBeforeClose()) {
            return
        }

        pending.value = true

        knowledgeBaseStore.contentLanguage = lang

        if (!knowledgeBaseStore.currentArticle.new) {
            try {
                const data = await useApi().knowledgeBase.oneArticle({
                    id: knowledgeBaseStore.currentArticle._id,
                    contentLanguage: knowledgeBaseStore.contentLanguage,
                })

                knowledgeBaseStore.setArticle(data)
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                pending.value = false

                return
            }
        }

        try {
            await knowledgeBaseStore.refreshTree()
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            pending.value = false

            return
        }

        navigateTo({
            query: {
                ...route.query,
                lang,
            },
        })

        useNotify().push({
            type: 'success',
            tag: 'kb',
            text: useLang().t('content-language-changed'),
        })

        pending.value = false
    }
</script>

<template>
    <AppFormFieldSelect
        :model-value="knowledgeBaseStore.contentLanguage"
        name="language"
        grouped
        :options="options"
        :dropdown-args="{ width: maxTablet ? 180 : 300, offsetY: 10 }"
        @update:model-value="changeContentLanguage"
    >
        <template #field="{ toggle, active }">
            <AppButton
                secondary
                :loading="pending"
                class="!py-2 !px-4"
                @click="toggle"
            >
                <slot name="activator-icon">
                    <component
                        :is="iconComponents[knowledgeBaseStore.contentLanguage]"
                        size="16"
                        color="#000"
                    />
                </slot>

                <slot
                    name="activator-text"
                    :active="active"
                >
                    <span class="mx-2">
                        {{ languageStore.supportedLocalesByIso[knowledgeBaseStore.contentLanguage].name }}
                    </span>
                </slot>

                <AppIconChevronDown
                    size="16"
                    color="#000"
                    :rotate="active"
                />
            </AppButton>
        </template>

        <template #option-text="{ option }">
            <span class="flex gap-4">
                <component
                    :is="iconComponents[option.value]"
                    size="20"
                    color="#000"
                />

                <span class="font-medium">
                    {{ option.text }}
                </span>
            </span>
        </template>
    </AppFormFieldSelect>
</template>
