<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.00061 1.00061C2.34376 1.00061 1.00061 2.34376 1.00061 4.00061V20.0006C1.00061 21.6575 2.34376 23.0006 4.00061 23.0006H20.0006C21.6575 23.0006 23.0006 21.6575 23.0006 20.0006V4.00061C23.0006 2.34376 21.6575 1.00061 20.0006 1.00061H4.00061ZM3.00061 4.00061C3.00061 3.44833 3.44833 3.00061 4.00061 3.00061H20.0006C20.5529 3.00061 21.0006 3.44833 21.0006 4.00061V6H3.00061V4.00061ZM3.00061 18V20.0006C3.00061 20.5529 3.44833 21.0006 4.00061 21.0006H20.0006C20.5529 21.0006 21.0006 20.5529 21.0006 20.0006V18H3.00061Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
