<script
    lang="ts"
    setup
>
    import type { KBItem } from '~/ts/types/knowledge-base'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'
    import filterItems from '~/helpers/knowledge-base/filterItems'

    type DraggableItemData = {
        el: HTMLElement
        key: string
        parentKey: string
    }

    type TargetItemData = {
        key: string
        parentKey: string
        hasChildren: boolean
    }

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const { maxTablet } = useWindowSize()

    const draggableItemData = ref<DraggableItemData>()

    const filteredItems = computed<KBItem[]>(() => {
        return filterItems(knowledgeBaseStore.tree.children, knowledgeBaseStore.search)
    })

    const dragOverThrottle = {
        started: false,
        timer: null,
        handler(callback) {
            if (this.started) {
                return
            }

            this.started = true

            callback()

            clearTimeout(this.timer)

            this.timer = setTimeout(() => {
                this.started = false
                this.timer = null
            }, 100)
        },
    }

    const startDrag = (event) => {
        event.dataTransfer.dropEffect = 'move'
        event.dataTransfer.effectAllowed = 'move'

        event.target.classList.add('opacity-40')

        const ghostEl = event.target.cloneNode(true)

        ghostEl.style.overflow = 'hidden'
        ghostEl.style.position = 'absolute'
        ghostEl.style.height = '32px'

        document.body.appendChild(ghostEl)

        event.dataTransfer.setDragImage(ghostEl, 0, 0)

        draggableItemData.value = {
            el: event.target,
            key: event.target.dataset.key,
            parentKey: event.target.dataset.parentKey,
        }
    }

    const dragEnter = (event) => {
        if (
            !draggableItemData.value
            || (event.target.dataset.draggable !== 'true')
            || draggableItemData.value.el.contains(event.target)
        ) {
            return
        }

        const targetItemData = prepareTargetItemData(event.target)

        if (targetItemData.key !== draggableItemData.value.key) {
            event.target.dataset.dragActionInsertAvailable = true

            if (targetItemData.hasChildren) {
                event.target.dataset.dragActionAppendAvailable = true
            }
        }

        // eslint-disable-next-line @stylistic/max-len
        event.target.dataset.dragActionAvailable = !!event.target.dataset.dragActionInsertAvailable || !!event.target.dataset.dragActionAppendAvailable
    }

    const dragOver = (event) => {
        dragOverThrottle.handler(() => {
            if (event.target.dataset.dragActionAvailable !== 'true') {
                return
            }

            const cursorPosition = event.clientY
            const boundingClientRect = event.target.getBoundingClientRect()

            let insertBefore, insertAfter

            if (event.target.dataset.dragActionInsertAvailable) {
                // Фактичну висоту (boundingClientRect.height) замінено на фіксовану, оскільки відкрита категорія має більшу висоту
                const itemHeight = 32

                if (event.target.dataset.dragActionAppendAvailable) {
                    insertBefore = cursorPosition < (boundingClientRect.y + itemHeight / 100 * 25)
                    insertAfter = cursorPosition > (boundingClientRect.y + itemHeight / 100 * 75)
                } else {
                    insertBefore = cursorPosition < (boundingClientRect.y + itemHeight / 100 * 50)
                    insertAfter = cursorPosition > (boundingClientRect.y + itemHeight / 100 * 25)
                }
            }

            const appendTo = event.target.dataset.dragActionAppendAvailable
                && !insertBefore
                && !insertAfter

            if (appendTo) {
                event.target.dataset.dragAction = 'appendTo'
            } else if (insertBefore) {
                event.target.dataset.dragAction = 'insertBefore'
            } else if (insertAfter) {
                event.target.dataset.dragAction = 'insertAfter'
            }
        })
    }

    const dragLeave = (event) => {
        clearDraggableTarget(event.target)
    }

    const onDrop = (event) => {
        if (!draggableItemData.value) {
            return
        }

        const dragAction = event.target.dataset.dragAction

        if (!dragAction) {
            return
        }

        const targetItemData = prepareTargetItemData(event.target)
        const draggableItemDataCopy = cloneValue(draggableItemData.value)

        clearDraggableTarget(event.target)

        draggableItemData.value = null

        if (dragAction === 'appendTo') {
            return appendItem(targetItemData, draggableItemDataCopy)
        }

        if ([ 'insertBefore', 'insertAfter' ].includes(dragAction)) {
            return moveItem(targetItemData, draggableItemDataCopy, dragAction)
        }
    }

    const dragEnd = (event) => {
        event.target.classList.remove('opacity-40')

        clearDraggableTarget(event.target)

        setTimeout(() => {
            if (draggableItemData.value) {
                draggableItemData.value = null
            }
        }, 200)
    }

    const moveItem = async (
        targetItemData: TargetItemData,
        draggableItemData: DraggableItemData,
        dragAction: 'insertBefore' | 'insertAfter',
    ) => {
        const treeItemsCopy = cloneValue(knowledgeBaseStore.tree.children)

        const targetItemContext = getItemByKey(targetItemData.key.split('-'), treeItemsCopy)
        const draggableItemContext = getItemByKey(draggableItemData.key.split('-'), treeItemsCopy)

        const draggableItemCopy = cloneValue(draggableItemContext.item)
        const draggableItemId = draggableItemContext.item._id

        const sameParent = targetItemData.parentKey === draggableItemData.parentKey

        let newIndex = (sameParent && (targetItemContext.index > draggableItemContext.index))
            ? targetItemContext.index
            : targetItemContext.index + 1

        if (newIndex && (dragAction === 'insertBefore')) {
            newIndex--
        }

        draggableItemContext.parentChildren.splice(draggableItemContext.index, 1)

        targetItemContext.parentChildren.splice(newIndex, 0, draggableItemCopy)

        knowledgeBaseStore.tree.children = treeItemsCopy

        try {
            await useApi().knowledgeBase.moveItem({
                id: draggableItemId,
                data: {
                    node_id: targetItemContext.item._id,
                    operation: dragAction,
                },
            })

            knowledgeBaseStore.findItemParent(draggableItemContext.item)
                .children
                .find(v => v._id === draggableItemContext.item._id)
                .parent_id = targetItemContext.item.parent_id

            useNotify().push({
                type: 'success',
                tag: 'kb',
                text: useLang().t('successfully-updated'),
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    }

    const appendItem = async (targetItemData: TargetItemData, draggableItemData: DraggableItemData) => {
        const treeItemsCopy = cloneValue(knowledgeBaseStore.tree.children)

        const targetItemContext = getItemByKey(targetItemData.key.split('-'), treeItemsCopy)
        const draggableItemContext = getItemByKey(draggableItemData.key.split('-'), treeItemsCopy)
        const draggableItemId = draggableItemContext.item._id

        targetItemContext.item.children.push(draggableItemContext.item)

        draggableItemContext.parentChildren.splice(draggableItemContext.index, 1)

        knowledgeBaseStore.tree.children = treeItemsCopy

        try {
            await useApi().knowledgeBase.moveItem({
                id: draggableItemId,
                data: {
                    node_id: targetItemContext.item._id,
                    operation: 'appendTo',
                },
            })

            knowledgeBaseStore.findItemParent(draggableItemContext.item)
                .children
                .find(v => v._id === draggableItemContext.item._id)
                .parent_id = targetItemContext.item._id

            useNotify().push({
                type: 'success',
                tag: 'kb',
                text: useLang().t('successfully-updated'),
            })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            /* empty */
        }
    }

    const clearDraggableTarget = (target) => {
        delete target.dataset.dragActionInsertAvailable
        delete target.dataset.dragActionAppendAvailable
        delete target.dataset.dragActionAvailable
        delete target.dataset.dragAction
    }

    const prepareTargetItemData = (targetItem: HTMLDivElement): TargetItemData => {
        return {
            key: targetItem.dataset.key,
            parentKey: targetItem.dataset.parentKey,
            hasChildren: targetItem.dataset.hasChildren === 'true',
        }
    }

    const getItemByKey = (indexes, children) => {
        const index = +indexes.shift()

        if (indexes.length) {
            return getItemByKey(indexes, children[index].children)
        } else {
            return {
                index,
                item: children[index],
                parentChildren: children,
            }
        }
    }
</script>

<template>
    <div
        class="
            max-tablet:z-[10]
            max-tablet:absolute
            max-tablet:top-0
            max-tablet:left-0
            flex
            flex-col
            tablet:max-w-[350px]
            min-w-full
            tablet:min-w-[350px]
            max-tablet:w-full
            h-full
            py-6
            bg-white
        "
        data-key="root"
        @dragover.prevent="dragOver"
        @dragenter.prevent="dragEnter"
        @dragleave.prevent="dragLeave"
        @dragend.prevent="dragEnd"
        @drop="onDrop"
    >
        <AppFormFieldSearch
            v-model.trim="knowledgeBaseStore.search"
            name="search"
            :placeholder="$t('search-articles-and-categories')"
            class="!w-auto mx-4"
        />

        <AppDropdown
            from-right
            width="auto"
            :offset-x="maxTablet ? undefined : '-8'"
        >
            <template #activator="{ toggle, active }">
                <div
                    class="
                        whitespace-nowrap
                        flex
                        items-center
                        justify-between
                        gap-1
                        mt-4
                        mb-2
                        mx-4
                        pl-4
                        pr-1
                        text-[10px]
                        text-[#8a8f9e]
                        font-medium
                        leading-[120%]
                        uppercase
                    "
                >
                    {{ $t('knowledge-base') }}

                    <AppButtonIcon
                        v-slot="{ color }"
                        extra-small
                        :active="active"
                        :disabled="!!knowledgeBaseStore.search"
                        @click="toggle"
                    >
                        <AppIconPlus
                            size="16"
                            :color="color"
                        />
                    </AppButtonIcon>
                </div>
            </template>

            <template #default="{ activeDetails, updateActiveDetails, close }">
                <KnowledgeBaseNavigationCategoryForm
                    v-if="activeDetails === 'create-category'"
                    key="category-form"
                    :item-or-parent-item="knowledgeBaseStore.tree"
                    @close="close()"
                />

                <div
                    v-else
                    key="adding-menu"
                    class="flex flex-col gap-1 w-[224px]"
                >
                    <AppDropdownItem @click="updateActiveDetails('create-category')">
                        <AppIconFolderAdd size="20" />

                        <span class="ml-4 font-medium">
                            {{ $t('create-category') }}
                        </span>
                    </AppDropdownItem>

                    <AppDropdownItem @click="knowledgeBaseStore.createArticle(knowledgeBaseStore.tree, close)">
                        <AppIconDocumentAdd size="20" />

                        <span class="ml-4 font-medium">
                            {{ $t('create-article') }}
                        </span>
                    </AppDropdownItem>
                </div>
            </template>
        </AppDropdown>

        <div class="flex flex-col gap-1 mx-4">
            <div
                v-if="!filteredItems.length"
                key="no-search-results"
                class="py-2 px-4 text-[14px] text-[#8a8f9e]"
            >
                {{ $t(knowledgeBaseStore.search ? 'nothing-found' : 'there-are-no-articles') }}
            </div>

            <template
                v-for="(item, index) in filteredItems"
                :key="item._id"
            >
                <KnowledgeBaseNavigationArticleNew
                    v-if="item.new"
                    key="new-article"
                />

                <KnowledgeBaseNavigationItem
                    v-else
                    :key="item._id"
                    :item="item"
                    :dragging="!!draggableItemData"
                    :data-key="'' + index"
                    data-parent-key="root"
                    @start-drag="startDrag"
                />
            </template>
        </div>
    </div>
</template>
