<script
    lang="ts"
    setup
>
    import type { AppSegmentControlItem } from '~/ts/types/app'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const { t } = useLang()
    const { maxTablet } = useWindowSize()

    const segments: AppSegmentControlItem[] = [
        {
            value: 'normal',
            text: 'normal',
            tooltipText: t('editing'),
            tooltipArgs: {
                direction: 'down',
            },
        },
        {
            value: 'preview',
            text: 'preview',
            tooltipText: t('viewing'),
            tooltipArgs: {
                direction: 'down',
            },
        },
    ]

    watch(() => knowledgeBaseStore.currentArticle, () => {
        if (knowledgeBaseStore.contentMode !== 'normal') {
            knowledgeBaseStore.contentMode = 'normal'
        }
    })
</script>

<template>
    <AppSegmentControl
        v-model="knowledgeBaseStore.contentMode"
        :items="segments"
        :disabled="!knowledgeBaseStore.currentArticle.title"
        :disable-tooltips="maxTablet"
        item-width="auto"
        item-class="flex-1"
        class="basis-1/3 max-w-[140px]"
    >
        <template #item:normal="{ item }">
            <AppIconDocumentEdit
                size="16"
                :color="knowledgeBaseStore.contentMode === item.value ? '#000' : undefined"
            />
        </template>

        <template #item:preview="{ item }">
            <AppIconEye
                size="16"
                :color="knowledgeBaseStore.contentMode === item.value ? '#000' : undefined"
            />
        </template>
    </AppSegmentControl>
</template>
