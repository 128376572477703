<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.51997 15.96L5.71997 17.76C6.15197 18.272 6.63997 18.696 7.18397 19.032C8.44797 19.864 9.95997 20.28 11.72 20.28C13.528 20.28 14.976 19.808 16.064 18.864C17.168 17.904 17.72 16.736 17.72 15.36C17.72 14.4376 17.5199 13.6509 17.1197 13H19V11H14.4255C14.0586 10.8549 13.6768 10.7163 13.28 10.584C12.512 10.328 11.8 10.112 11.144 9.936C10.488 9.76 9.93597 9.488 9.48797 9.12C9.05597 8.736 8.83997 8.256 8.83997 7.68C8.83997 7.04 9.11197 6.504 9.65597 6.072C10.216 5.624 10.984 5.4 11.96 5.4C12.888 5.4 13.752 5.664 14.552 6.192C14.856 6.384 15.152 6.64 15.44 6.96L17.24 5.16C16.888 4.76 16.44 4.4 15.896 4.08C14.744 3.36 13.432 3 11.96 3C10.232 3 8.83997 3.456 7.78397 4.368C6.72797 5.264 6.19997 6.368 6.19997 7.68C6.19997 8.656 6.41597 9.48 6.84797 10.152C7.06528 10.478 7.30705 10.7606 7.57329 11H5V13H12.3983C12.5262 13.0361 12.6521 13.0708 12.776 13.104C13.432 13.28 13.976 13.56 14.408 13.944C14.856 14.312 15.08 14.784 15.08 15.36C15.08 16.064 14.776 16.664 14.168 17.16C13.576 17.64 12.76 17.88 11.72 17.88C10.52 17.88 9.45597 17.56 8.52797 16.92C8.11197 16.616 7.77597 16.296 7.51997 15.96Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
