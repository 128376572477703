<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                d="M10.5899 13.4099C10.9999 13.7999 10.9999 14.4399 10.5899 14.8299C10.1999 15.2199 9.55995 15.2199 9.16995 14.8299C7.21995 12.8799 7.21995 9.70995 9.16995 7.75995L12.7099 4.21995C14.6599 2.26995 17.8299 2.26995 19.7799 4.21995C21.7299 6.16995 21.7299 9.33995 19.7799 11.2899L18.2899 12.7799C18.2999 11.9599 18.1699 11.1399 17.8899 10.3599L18.3599 9.87995C19.5399 8.70995 19.5399 6.80995 18.3599 5.63995C17.1899 4.45995 15.2899 4.45995 14.1199 5.63995L10.5899 9.16995C9.40995 10.3399 9.40995 12.2399 10.5899 13.4099ZM13.4099 9.16995C13.7999 8.77995 14.4399 8.77995 14.8299 9.16995C16.7799 11.1199 16.7799 14.2899 14.8299 16.2399L11.2899 19.7799C9.33995 21.7299 6.16995 21.7299 4.21995 19.7799C2.26995 17.8299 2.26995 14.6599 4.21995 12.7099L5.70995 11.2199C5.69995 12.0399 5.82995 12.8599 6.10995 13.6499L5.63995 14.1199C4.45995 15.2899 4.45995 17.1899 5.63995 18.3599C6.80995 19.5399 8.70995 19.5399 9.87995 18.3599L13.4099 14.8299C14.5899 13.6599 14.5899 11.7599 13.4099 10.5899C12.9999 10.1999 12.9999 9.55995 13.4099 9.16995Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
