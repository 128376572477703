<script
    lang="ts"
    setup
>
    type Props = {
        items: string[]
    }

    type Emit = {
        (event: 'select', index: number): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()

    const containerRef = ref<HTMLDivElement | undefined>()

    const { arrivedLeft } = useScroll(containerRef)

    onMounted(() => {
        watch(() => props.items, () => {
            setTimeout(() => {
                const { scrollLeft, scrollWidth, clientWidth } = containerRef.value
                const newScrollLeft = scrollWidth - clientWidth

                if (scrollLeft === newScrollLeft) {
                    return
                }

                animate({
                    duration: 0.2,
                    timing: animationTiming.makeEaseOut(animationTiming.circ),
                    draw(progress) {
                        const currentScrollLeft = newScrollLeft * progress

                        if (currentScrollLeft > scrollLeft) {
                            containerRef.value.scrollLeft = currentScrollLeft
                        }
                    },
                })
            }, 0)
        }, { immediate: true })
    })
</script>

<template>
    <div
        ref="containerRef"
        :class="$style['knowledge-base__breadcrumbs']"
    >
        <Transition name="fade-transition">
            <div
                v-if="!arrivedLeft"
                key="gradient"
                :class="$style['knowledge-base__breadcrumbs__gradient']"
            ></div>
        </Transition>

        <div
            key="first"
            :class="$style['knowledge-base__breadcrumbs__item']"
            :title="$t('knowledge-base')"
            @click="emit('select', -1)"
        >
            <AppIconHome
                size="20"
                color="#000"
            />
        </div>

        <template
            v-for="(item, index) in props.items"
            :key="index"
        >
            <div
                :class="[
                    $style['knowledge-base__breadcrumbs__item'],
                    $style['knowledge-base__breadcrumbs__item--separator'],
                ]"
            >
                /
            </div>

            <div
                v-if="props.items.length === (index + 1)"
                :key="'active-item-' + item"
                :class="[
                    $style['knowledge-base__breadcrumbs__item'],
                    $style['knowledge-base__breadcrumbs__item--active'],
                ]"
                :title="item"
            >
                <div>{{ item }}</div>
            </div>

            <div
                v-else
                :key="'item-' + item"
                :class="$style['knowledge-base__breadcrumbs__item']"
                :title="item"
                @click="emit('select', index)"
            >
                <div>{{ item }}</div>
            </div>
        </template>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .knowledge-base__breadcrumbs
        overflow-x: auto
        display: flex
        align-items: center
        min-height: 20px
        height: 20px

        &::-webkit-scrollbar
            display: none
            width: 0
            height: 0

        &__gradient
            pointer-events: none
            position: absolute
            width: 60px
            height: 20px
            background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0.09) 100%)

        &__item
            pointer-events: none
            white-space: nowrap
            display: flex
            font-size: 14px
            font-weight: 500
            line-height: 17px

            &:last-of-type
                max-width: 80%

            &:not(:last-of-type)
                max-width: 33%

            > div
                overflow: hidden
                text-overflow: ellipsis

            &--separator
                margin: 0 8px

            &--separator,
            &--active
                color: #8a8f9e

            &:not(&--active):not(&--separator)
                cursor: pointer
</style>
