<script
    lang="ts"
    setup
>
    import type { AppAnalyticsItem } from '~/ts/types/app'
    import { useKnowledgeBaseStore } from '~/stores/knowledge-base'

    const knowledgeBaseStore = useKnowledgeBaseStore()

    const showFeedbackModal = ref<boolean>(false)

    const { t } = useLang()

    const analyticsItems = computed<AppAnalyticsItem[]>(() => [
        {
            title: t('kb-analytics-1'),
            value: knowledgeBaseStore.currentArticle.views,
            class: 'w-full',
        },
        {
            title: t('kb-analytics-2'),
            value: knowledgeBaseStore.currentArticle.likes,
            class: 'flex-1',
        },
        {
            title: t('kb-analytics-3'),
            value: knowledgeBaseStore.currentArticle.dislikes,
            class: 'flex-1',
        },
        {
            title: t('kb-analytics-4'),
            value: knowledgeBaseStore.currentArticle.reviewsCount,
            class: 'w-full',
        },
    ])
</script>

<template>
    <div class="flex flex-wrap gap-2 pt-2">
        <AppAnalyticsCard
            v-for="(item, index) in analyticsItems"
            :key="item.title"
            :class="item.class"
        >
            <template #title>
                {{ item.title }}
            </template>

            <template #value>
                {{ item.value }}
            </template>

            <template
                v-if="index === analyticsItems.length - 1"
                #after-value
            >
                <div class="flex flex-1 justify-end">
                    <AppButton
                        secondary
                        small
                        @click="showFeedbackModal = true"
                    >
                        {{ $t('kb-analytics-5') }}
                    </AppButton>
                </div>
            </template>
        </AppAnalyticsCard>

        <LazyKnowledgeBaseSidebarInfoAnalyticsFeedbackModal
            v-if="showFeedbackModal"
            key="feedback-modal"
            @close="showFeedbackModal = false"
        />
    </div>
</template>
